/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

var Prism = require("prismjs")
require("prismjs/components/prism-markup-templating")
require("prismjs/components/prism-scss")
require("prismjs/components/prism-php")

function ready(fn) {
  if (document.readyState != "loading") {
    fn()
  } else {
    document.addEventListener("DOMContentLoaded", fn)
  }
}

ready(() => {
  setTimeout(() => {
    Prism.highlightAll()
  }, 500)
})

exports.onRouteUpdate = ({ location, prevLocation }) => {
  ready(() => {
    setTimeout(() => {
      Prism.highlightAll()
    }, 500)
  })
}
